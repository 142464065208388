<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'CustomerList', }" >會員管理</b-breadcrumb-item >
            <b-breadcrumb-item active>會員等級列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">會員等級列表</h4>
      <div class="row my-3">
        <div class="col-12 col-xl-6"></div>
        <div
          class="
            col-12 col-xl-6
            d-flex
            align-items-start align-items-xl-center
            justify-content-end
          "
        >
          <b-button
            v-if="$permissions.has($permissions.consts.CUSTOMER_LIST_IMPORT)"
            variant="primary"
            class="flex-shrink-0 mb-2 mb-xl-0"
            :to="{ name: 'CustomerLevelsCreate' }"
          >
            新增會員等級
          </b-button>
          <b-input-group class="mb-2 mb-xl-0 ml-2 ml-xl-4 col-xl-4">
            <b-form-input v-model="keyword" @keyup.enter="getCustomerLevelsList" placeholder="搜尋名字、描述"></b-form-input>
            <b-input-group-append>
              <b-button @click="getCustomerLevelsList" ><i class="fa fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            striped
            hover
            responsive
            :items="levels"
            :fields="fields"
            :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <router-link :to="{ name: 'CustomerLevelsEdit', params: { id: data.item.id } }" >{{ hiddenString(data.item.name, 20) }}</router-link>
            </template>
            <template #cell(start_to_end)="data">
              {{ convertToStartToEnd(data.item) }}
            </template>
            <template #cell(is_enabled)="data">
              <b-badge
                :variant="!!data.item.is_enabled === true ? 'success' : 'danger'"
                class="mr-1"
                >{{ !!data.item.is_enabled === true ? '啟用' : '停用' }}</b-badge
              >
            </template>
            <template #cell(actions)="data">
              <b-button
                v-if="checkPermission([consts.CUSTOMER_LEVEL_LIST])"
                class="ml-2"
                variant="inverse-primary"
                :to="{ name: 'CustomerLevelsCustomerList', params: { id: data.item.id } }"
              >會員列表</b-button>
              <b-button
                v-if="checkPermission([consts.CUSTOMER_LEVEL_EDIT])"
                class="ml-2"
                variant="inverse-warning"
                :to="{ name: 'CustomerLevelsEdit', params: { id: data.item.id } }"
              >編輯</b-button>

              <b-button
                v-if="checkPermission([consts.CUSTOMER_LEVEL_DELETE])"
                class="ml-2"
                variant="inverse-danger"
                @click="handleDelete(data.item)"
              >刪除</b-button>
            </template>
          </b-table>
        </div>
      </div>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import customerLevelApi from "@/apis/customer-levels";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import moment from "moment";

import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  data() {
    const baseFields = [
        { key: "order", label: "排序" },
        { key: "name", label: "名稱" },
        { key: "code", label: "代碼" },
        { key: "description", label: "說明" },
        { key: "start_to_end", label: "有效期間" },
        { key: "is_enabled", label: "是否啟用" },
        { key: "actions", label: '管理' },
    ];

    return {
      consts,
      isLoading: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      isFetchCustomerList: false, // old
      selectedMerchantID: 'cross',
      isBinded: null,
      bindedOptions: [
        { value: null, text: '無論會員有無綁定' },
        { value: true, text: '已綁定' },
        { value: false, text: '未綁定' },
      ],
      merchants: [],
      keyword: null,
      customers: [], // decp
      levels: [],
      baseFields: baseFields,
      fields: baseFields,
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },

  watch: {
    currentPage() {
      this.getCustomerLevelsList();
    }
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      await this.getCustomerLevelsList();
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    convertToStartToEnd(level) {
      let startAt = (level.start_at) ? moment(level.start_at).format('YYYY-MM-DD') : '';
      let endAt = (level.end_at) ? moment(level.end_at).format('YYYY-MM-DD') : '';

      return startAt + ' - ' + endAt;
    },
    async getCustomerLevelsList() {
      try {
        this.isLoading = true;

        const { data } = await customerLevelApi.list({
          per_page: this.per_page,
          page: this.currentPage,
          keyword: this.keyword,
        })

        this.levels = data.data
        this.totalRows = data.total;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    hiddenString(value, length) {
      value = value || ''
      const splittedValue = [...value]
      if (splittedValue.length <= length) {
        return value
      }
      return splittedValue.slice(0, length).join('') + '...'
    },
    async handleDelete(level) {
      let confirm = await this.$swal.fire({
        type: "warning",
        title: "刪除會員等級",
        text: `您確認要刪除 ${level.name} 這個會員等級嗎？所有此等級會員將會降回預設等級`,
        confirmButtonText: "確認",
        cancelButtonText: "取消",
        showCancelButton: true,
      })

      if (!confirm.value) return

      try {
        await customerLevelApi.delete(level.id)
        this.$swal('刪除成功', '', 'success');
        await this.getCustomerLevelsList();
      } catch (error) {
        console.error(error)
        this.$swal('錯誤', '', 'error')
      }
    },
  },
};
</script>

<style></style>
