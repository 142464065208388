import https from "./https";
import store from "@/store";

const customerLevels = {
  list(params) {
    const organization = store.state.general.organization;
    return https.get(
      `/admin/organizations/${organization.id}/customer-levels/list`,
      {
        params,
      }
    );
  },

  create(data) {
    const organization = store.state.general.organization;

    return https.post(
      `/admin/organizations/${organization.id}/customer-levels/create`,
      data
    );
  },

  update(levelId, data) {
    return https.put(`/admin/customer-levels/${levelId}`, data);
  },

  show(levelId) {
    return https.get(`/admin/customer-levels/${levelId}`);
  },

  getCustomers(levelId) {
    return https.get(`/admin/customer-levels/${levelId}/customers`);
  },

  getLogs(levelId) {
    return https.get(`/admin/customer-levels/${levelId}/logs`);
  },

  bindCustomer(levelId, customerId) {
    return https.post(`/admin/customer-levels/${levelId}/bind-customer`, {customer_id: customerId});
  },

  unbindCustomer(levelId, customerId) {
    return https.post(`/admin/customer-levels/${levelId}/unbind-customer`, {customer_id: customerId});
  },
  importCustomerPreview (levelId, formData) {
    const url = `/admin/customer-levels/${levelId}/import-preview`

    return https.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  importCustomer (levelId, formData) {
    const url = `/admin/customer-levels/${levelId}/import`

    return https.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  delete(levelId) {
    return https.delete(`/admin/customer-levels/${levelId}`);
  },

};

export default customerLevels;
